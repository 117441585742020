// counterSlice.ts
import { createSlice } from '@reduxjs/toolkit';



const initialState: any = {
  jobdetail:{
 
  },
  // jobCardList: {

  // }
};



export const existingJobData = createSlice({
  name: 'existingJobData',
  initialState,
  reducers: {
    jobSelected: (state,payloadAction:any) => {
      state.jobdetail=payloadAction.payload;
    },
    // jobCardSelected: (state, data: any) => {
    //   state.jobCardList = data.payload;
    // } 
  },
});

export const { jobSelected, } = existingJobData.actions;
export default existingJobData.reducer;
