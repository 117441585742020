import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { store } from './State_Management/Redux/store.ts';
import { Provider } from 'react-redux';
import Cookies from 'js-cookie';
import "./styles/styles.scss";

const AdminRoutes = lazy(() => import('./components/Admin.tsx'));
const ClientRoutes = lazy(() => import('./components/Client.tsx'));
const Login = lazy(() => import('./components/Login.tsx'));
const OAuthSuccess = lazy( () => import ('./components/AuthSignin/OAuthSuccess.tsx'))

// Create a separate component for handling authentication logic
const AuthHandler: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const cookieJwt = Cookies.get('x-auth-cookie');
    if (cookieJwt) {
      Cookies.remove('x-auth-cookie');
      document.cookie = `${'x-auth-cookie'}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${'homazon.co.uk'};path=/;`;
      localStorage.setItem('homazonTK', cookieJwt);
      navigate('client/home');
      console.log(cookieJwt);
    }
  }, [navigate]);

  return null; // This component does not render anything
};

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <AuthHandler /> {/* Place AuthHandler here */}
          <Routes>
            <Route path="/admin/*" element={<AdminRoutes />} />
            <Route path="/client/*" element={<ClientRoutes />} />
            <Route path="/login" element={<Login />} />
            <Route path="/oauth-success" element={< OAuthSuccess />} />
            <Route path="/" element={<div>Home</div>} />
          </Routes>
        </Suspense>
      </Router>
    </Provider>
  );
};

export default App;
